





























import { Component, Prop, Vue } from "vue-property-decorator";
import SelectMenu from "@/components/SelectMenu.vue";
import { hostGame } from "@/rest/Game";
import GameStateModule from "../store/modules/GameStateModule";
import { messaging } from "./../rio-proto.js";

@Component({
  components: {
    SelectMenu,
  },
})
export default class GameCard extends Vue {
  @Prop() private card!: messaging.CardDeckCards;
  @Prop() private playerName!: string;
  @Prop() private pick!: boolean;
  @Prop() private selected!: boolean;
  @Prop() private isUnanimousVote!: boolean;
  @Prop() private isMajorityVote!: boolean;
  @Prop() private highlighted!: boolean;

  private hover = false;

  onCardSelect(): void {
    if (this.isMajorityVote) {
      this.$emit("joinMajority", this.card);
      return;
    }
    this.$emit("cardSelect", this.card);
  }

  cardDisplayName(card: messaging.CardDeckCards): string {
    switch (card) {
      // default cards
      case messaging.CardDeckCards.DEFAULT_ZERO:
        return "0";
      case messaging.CardDeckCards.DEFAULT_PFIVE:
        return "0.5";
      case messaging.CardDeckCards.DEFAULT_ONE:
        return "1";
      case messaging.CardDeckCards.DEFAULT_TWO:
        return "2";
      case messaging.CardDeckCards.DEFAULT_THREE:
        return "3";
      case messaging.CardDeckCards.DEFAULT_FIVE:
        return "5";
      case messaging.CardDeckCards.DEFAULT_EIGHT:
        return "8";
      case messaging.CardDeckCards.DEFAULT_THIRTEEN:
        return "13";
      case messaging.CardDeckCards.DEFAULT_TWENTY:
        return "20";
      case messaging.CardDeckCards.DEFAULT_FOURTY:
        return "40";
      case messaging.CardDeckCards.DEFAULT_HUNDRED:
        return "100";

      // tshirt sizes
      case messaging.CardDeckCards.TSHIRT_S:
        return "S";
      case messaging.CardDeckCards.TSHIRT_M:
        return "M";
      case messaging.CardDeckCards.TSHIRT_L:
        return "L";
      case messaging.CardDeckCards.TSHIRT_XL:
        return "XL";
    }

    return "";
  }

  get cardBGClasses(): string[] {
    let classes: Array<string> = [];

    if (this.selected) {
      if (this.hover) {
        classes.push("border-success-500");
        classes.push("bg-back");
        classes.push("text-success-500");
      } else {
        classes.push("border-success");
        classes.push("bg-back");
        classes.push("text-success");
      }
    } else if (this.highlighted) {
      if (this.hover) {
        classes.push("border-danger-400");
        classes.push("bg-back");
        classes.push("text-danger-400");
      } else {
        classes.push("border-danger");
        classes.push("bg-back");
        classes.push("text-danger");
      }
    } else {
      if (this.hover) {
        classes.push("border-info");
        classes.push("text-info");
      } else {
        classes.push("border-back-600");
        classes.push("text-back-600");
      }
    }
    return classes;
  }
}
