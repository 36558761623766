















import { Component, Prop, Vue } from "vue-property-decorator";
import { messaging } from "./../rio-proto.js";

@Component({
  components: {},
})
export default class HintBannerBottom extends Vue {
  @Prop()
  private gameStatus!: messaging.GameStatus;
  @Prop()
  private isHost!: boolean;
  @Prop()
  private isUnanimousVote!: boolean;
  @Prop()
  private hasPicked!: boolean;

  mounted() {}

  get statusBGColor(): string {
    switch (this.gameStatus) {
      case messaging.GameStatus.NEW:
        return "bg-blue-600";

      case messaging.GameStatus.RUNNING:
        return "bg-green-600";

      case messaging.GameStatus.FINISHED:
        return "bg-primary";
    }
    return "";
  }

  get statusText(): string {
    switch (this.gameStatus) {
      case messaging.GameStatus.NEW:
        if (this.isHost) {
          return "Invite more team members, enter a story title and click refine to start the session.";
        }
        return "Waiting for the session host to start the refinement session.";

      case messaging.GameStatus.RUNNING:
        if (this.isHost || this.hasPicked) {
          return "Waiting for team members to pick their cards.";
        }
        return "Pick a card to estimate the story.";

      case messaging.GameStatus.FINISHED:
        if (this.isHost) {
          if (this.isUnanimousVote) {
            return "Enter a new story title and click switch to start refining!";
          }
          return "Let the team discuss their pics, then click re-estimate to continue.";
        }
        if (this.isUnanimousVote) {
          return "Waiting for host to pick a new story!";
        }
        return "Discuss your pick with the team.";
    }
    return "";
  }
}
