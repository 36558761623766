






















































import { Component, Prop, Vue } from "vue-property-decorator";
import { hostGame } from "@/rest/Game";
import { messaging } from "./../rio-proto.js";

@Component({
  components: {},
})
export default class UserList extends Vue {
  @Prop()
  private playerStatus!: messaging.PlayerUpdate;
  @Prop()
  private isHost!: boolean;

  mounted() {}

  getCardStatusClass(card: messaging.CardDeckCards): string {
    if (card === messaging.CardDeckCards.SPECIAL_NOTCHOSEN) {
      return "bg-red-400";
    }
    return "bg-green-400";
  }

  onInvite(): void {
    this.$emit("invite", true);
  }

  onQR(): void {
    this.$emit("qr", true);
  }

  onRemove(user: string): void {
    this.$emit("remove", user);
  }
}
