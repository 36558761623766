












import { Component, Prop, Vue } from "vue-property-decorator";
import { messaging } from "./../rio-proto.js";

@Component({
  components: {},
})
export default class GameStatus extends Vue {
  @Prop()
  private gameStatus!: messaging.GameStatus;
  @Prop()
  private isHost!: boolean;
  @Prop()
  private isUnanimousVote!: boolean;

  mounted() {}

  get statusBGColor(): string {
    switch (this.gameStatus) {
      case messaging.GameStatus.NEW:
        return "bg-success-600";

      case messaging.GameStatus.RUNNING:
        return "bg-back-200";

      case messaging.GameStatus.FINISHED:
        return "bg-success-500";
    }
    return "";
  }

  get statusText(): string {
    switch (this.gameStatus) {
      case messaging.GameStatus.NEW:
        if (this.isHost) {
          return "Invite more team members, enter a story title and click refine to start the session.";
        }
        return "Waiting for the session host to start the refinement session.";
      case messaging.GameStatus.RUNNING:
        return "Waiting for team members to pick their cards.";
      case messaging.GameStatus.FINISHED:
        if (this.isUnanimousVote) {
          return "Congratulations, you successfully refined your story!";
        }
        return "Voting is not yet unanimous.";
    }
    return "";
  }
}
